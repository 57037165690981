import { useUserContext } from "app/contexts";
import { useProvider } from "app/contexts/ProviderContext";
import { useEnabledDashboards, useHasRequiredPermission } from "app/hooks";
import { ADMIN_PERMISSIONS, ADMIN_PERMISSIONS_DEMO } from "app/utils/consts";
import { isEqual } from "app/utils/permissions";

import type { Permission } from "app/contexts";

interface BaseNavItems {
  label: string;
  id: string;
  isExternalLink?: boolean;
  mainPath?: string;
  permissionRequired?: {
    object: string;
    action: string;
  };
  demoDisabled?: boolean;
}

interface NavItemsLeafNode extends BaseNavItems {
  target: string;
  children?: never;
}

interface NavItemsBranchNode extends BaseNavItems {
  children: NavItem[];
  target?: never;
}

export type NavItem = NavItemsBranchNode | NavItemsLeafNode;

interface ContainesCurrentRouteProps {
  currentRoute: string;
  navItems: NavItem[];
}

export const containsCurrentRoute = ({
  currentRoute,
  navItems,
}: ContainesCurrentRouteProps): boolean =>
  navItems.some((navItem) =>
    navItem.target
      ? currentRoute.startsWith(navItem.target)
      : containsCurrentRoute({
          currentRoute,
          navItems: navItem.children ?? [],
        }),
  );

function filterItemsByPermissions(
  items: NavItem[],
  permissions: Permission[],
): NavItem[] {
  const isAllowed = (item: NavItem) => {
    const { permissionRequired } = item;
    return permissionRequired
      ? permissions.some((permission) =>
          isEqual(permission, permissionRequired),
        )
      : true;
  };

  return items.filter((item) => {
    item.children = item.children?.filter((child: NavItem) => isAllowed(child));
    return isAllowed(item);
  });
}

export function useNavItems(extraFields: NavItem[]): NavItem[][] {
  const permissions = useUserContext();
  const provider = useProvider();
  const dashboards = useEnabledDashboards(provider);
  const hasPermission = useHasRequiredPermission();
  const isDemo = hasPermission(ADMIN_PERMISSIONS_DEMO);

  const navItems: NavItem[] = [
    {
      label: "Overview",
      id: "overview",
      children: [
        {
          label: "Cloud",
          id: "cloud",
          target: "/overview",
        },
        {
          label: "Stacklet Activity",
          target: "/activity",
          id: "activity",
        },
        ...dashboards,
        {
          label: "Deployed Policies",
          id: "deployed-policies",
          target: "/policies",
        },
      ],
    },
    {
      label: "Cloud Ops",
      id: "cloudops",
      children: [
        {
          label: "Resource Matches",
          target: "/cloudops/resource-matches",
          id: "cloudops-resource-matches",
        },
        {
          label: "Resource Explorer",
          target: "/cloudops/resources",
          id: "cloudops-resources",
        },
      ],
    },
    {
      label: "Admin",
      id: "admin",
      children: [
        {
          label: "Accounts",
          target: "/admin/accounts",
          id: "admin-accounts",
        },
        {
          label: "Account Groups",
          target: "/admin/account-groups",
          id: "admin-account-groups",
        },
        {
          label: "Bindings",
          id: "admin-bindings",
          target: "/admin/bindings",
        },
        {
          label: "Policies",
          id: "admin-policies",
          target: "/admin/policies",
        },
        {
          label: "Policy Collections",
          id: "admin-policy-collections",
          target: "/admin/policy-collections",
        },
      ],
    },
    {
      label: "Notifications",
      id: "notifications",
      permissionRequired: ADMIN_PERMISSIONS,
      children: [
        {
          label: "Overview",
          id: "overview",
          target: "/notifications/overview",
          demoDisabled: isDemo,
        },
        {
          label: "Settings",
          id: "settings",
          target: "/notifications/settings",
          demoDisabled: isDemo,
        },
      ],
    },
  ];

  const navItemsBottom: NavItem[] = [...extraFields];

  const collapsedNavItems: NavItem[] = [
    {
      label: "Overview",
      target: "/overview",
      id: "overview",
    },
    {
      label: "Cloud Ops",
      target: "/cloudops/resource-matches",
      id: "cloudops",
    },
    {
      label: "Admin",
      target: "/admin/accounts",
      mainPath: "/admin",
      id: "admin",
      permissionRequired: ADMIN_PERMISSIONS,
    },
    {
      label: "Stacklet Tools",
      target: "",
      isExternalLink: true,
      id: "stacklet-tools",
    },
  ];

  const collapsedNavItemsBottom: NavItem[] = [
    {
      label: "Notifications",
      target: "/notifications",
      id: "notifications",
      permissionRequired: ADMIN_PERMISSIONS,
    },
  ];

  const items = filterItemsByPermissions(navItems, permissions);
  const collapsedItems = filterItemsByPermissions(
    collapsedNavItems,
    permissions,
  );
  const bottomItems = filterItemsByPermissions(navItemsBottom, permissions);
  const collapsedBottomItems = filterItemsByPermissions(
    collapsedNavItemsBottom,
    permissions,
  );

  return [items, collapsedItems, bottomItems, collapsedBottomItems];
}
