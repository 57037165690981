import LaunchIcon from "@mui/icons-material/Launch";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { COLORS, Tooltip } from "@stacklet/ui";
import { NavLink } from "react-router-dom";

import type { Theme } from "@mui/material/styles";

const styles = (theme: Theme) => ({
  textDecoration: "none",
  color: theme.palette.common.white,
  display: "flex",
  width: "100%",
  justifyContent: "flex-start",
  alignItem: "center",
  padding: theme.spacing(1.5, 3),
  borderLeft: "2px solid transparent",
  "&.active": {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: COLORS.navy.L10,
  },
  "&.level-1": {
    paddingLeft: theme.spacing(7),
  },
  "&.level-2": {
    paddingLeft: theme.spacing(9),
  },
});

const StyledNavLink = styled(NavLink)(({ theme }) => styles(theme));
const StyledExternalLink = styled(Link)(({ theme }) => styles(theme));
const StyledDisabledLink = styled(Box)(({ theme }) => styles(theme));

interface Props {
  children: JSX.Element;
  isExternalLink?: boolean;
  nestedLevel: number;
  target: string;
  demoDisabled?: boolean;
}

export default function SidebarLink({
  children,
  isExternalLink,
  nestedLevel,
  target,
  demoDisabled,
}: Props): JSX.Element {
  return !demoDisabled ? (
    <Box alignItems="center" display="flex">
      {isExternalLink ? (
        <StyledExternalLink
          className={`level-${nestedLevel}`}
          href={target}
          target="_blank"
        >
          {children}
          <LaunchIcon
            fontSize="inherit"
            sx={{ marginLeft: "9px", color: "inherit" }}
          />
        </StyledExternalLink>
      ) : (
        <StyledNavLink className={`level-${nestedLevel}`} to={target}>
          {children}
        </StyledNavLink>
      )}
    </Box>
  ) : (
    <Tooltip placement="right" title="Disabled in demo mode">
      <Box
        alignItems="center"
        display="flex"
        sx={{ opacity: 0.5, cursor: "not-allowed" }}
      >
        <StyledDisabledLink className={`level-${nestedLevel}`}>
          {children}
        </StyledDisabledLink>
      </Box>
    </Tooltip>
  );
}
